/* 

@font-face {
    font-family: "FivoSans-Black-Oblique";
    src: local("FivoSans-Black-Oblique"),
     url("./FivoSans/FivoSans-Black-Oblique.otf") format("opentype");
}

@font-face {
    font-family: "FivoSans-Black";
    src: local("FivoSans-Black"),
     url("./FivoSans/FivoSans-Black.otf") format("opentype");
}

@font-face {
    font-family: "FivoSans-Bold-Oblique";
    src: local("FivoSans-Bold-Oblique"),
     url("./FivoSans/FivoSans-Bold-Oblique.otf") format("opentype");
}

@font-face {
    font-family: "FivoSans-Bold";
    src: local("FivoSans-Bold"),
     url("./FivoSans/FivoSans-Bold.otf") format("opentype");
}

@font-face {
    font-family: "FivoSans-Heavy-Oblique";
    src: local("FivoSans-Heavy-Oblique"),
     url("./FivoSans/FivoSans-Heavy-Oblique.otf") format("opentype");
}

@font-face {
    font-family: "FivoSans-Heavy";
    src: local("FivoSans-Heavy"),
     url("./FivoSans/FivoSans-Heavy.otf") format("opentype");
}

@font-face {
    font-family: "FivoSans-Light-Oblique";
    src: local("FivoSans-Light-Oblique"),
     url("./FivoSans/FivoSans-Light-Oblique.otf") format("opentype");
}

@font-face {
    font-family: "FivoSans-Light";
    src: local("FivoSans-Light"),
     url("./FivoSans/FivoSans-Light.otf") format("opentype");
}

@font-face {
    font-family: "FivoSans-Medium-Oblique";
    src: local("FivoSans-Medium-Oblique"),
     url("./FivoSans/FivoSans-Medium-Oblique.otf") format("opentype");
}

@font-face {
    font-family: "FivoSans-Medium";
    src: local("FivoSans-Medium"),
     url("./FivoSans/FivoSans-Medium.otf") format("opentype");
}

@font-face {
    font-family: "FivoSans-Oblique";
    src: local("FivoSans-Oblique"),
     url("./FivoSans/FivoSans-Oblique.otf") format("opentype");
}

@font-face {
    font-family: "FivoSans-Regular";
    src: local("FivoSans-Regular"),
     url("./FivoSans/FivoSans-Regular.otf") format("opentype");
}

@font-face {
    font-family: "FivoSans-Thin-Oblique";
    src: local("FivoSans-Thin-Oblique"),
     url("./FivoSans/FivoSans-Thin-Oblique.otf") format("opentype");
}

@font-face {
    font-family: "FivoSans-Thin";
    src: local("FivoSans-Thin"),
     url("./FivoSans/FivoSans-Thin.otf") format("opentype");
}

 */





@font-face {
    font-family: "Fivo Sans";
    src: local("Fivo Sans"),
     url("./FivoSans/FivoSans-Bold.otf") format("opentype");
}

@font-face {
    font-family: "Fivo Sans";
    src: local("Fivo Sans"),
     url("./FivoSans/FivoSans-Medium.otf") format("opentype");
}
@font-face {
    font-family: "Fivo Sans";
    src: local("Fivo Sans"),
     url("./FivoSans/FivoSans-Regular.otf") format("opentype");
}